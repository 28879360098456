import React from 'react';
import {Link} from 'react-router-dom';

import logo from '../logo-sac.svg';

export default function TplHeader() {
  return (
    <div>
      <header className="App-header">
        <div className="App-container">
          <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <ul className="App-menu">
            {/*
              <li><Link to="/about" className="secondary">Sobre Costealo</Link></li>
            */}
            <li><a href="mailto:hola@costealo.com">hola@costealo.com</a></li>
          </ul>
          <ul className="App-menu-movil">
            {/*
              <li><Link to="/about"><img src="group-people-ic.png" alt="mail" /></Link></li>
            */}
            <li><a href="mailto:hola@costealo.com"><img src="mail-ic.png" alt="mail" /></a></li>
          </ul>
        </div>
      </header>
    </div>
  );
}