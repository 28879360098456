import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import TplHeader from './template/TplHeader';
import HomeIndex from './home/HomeIndex';
import AboutIndex from './about/AboutIndex';

import './App.css';

export default function App() {
  return (
  	<Router>
    	<div className="App">
      		<TplHeader />
	      	
	      	<Switch>
	      		<Route path="/about" component={AboutIndex} />
	      		<Route path="/" component={HomeIndex} />
	      	</Switch>
		</div>
	</Router>
  );
}