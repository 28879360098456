import React, {Component, MouseEvent} from 'react';

let items = {
  basic: [
    {id: 'B001', title: 'PÁGINA WEB', subtitle: 'Empresarial, Landing Page, Otros'},
    {id: 'B002', title: 'REDES SOCIALES', subtitle: 'Facebook, Instagram, Otros'}
  ],
  middle: [
    {id: 'M001', title: 'PÁGINA WEB', subtitle: 'Empresarial, Landing Page, Otros'},
    {id: 'M002', title: 'REDES SOCIALES', subtitle: 'Facebook, Instagram, Otros'},
    {id: 'M003', title: 'APPLICACIÓN MOVIL', subtitle: 'IOS, Android'},
    {id: 'M004', title: 'SEO Y POSICIONAMIENTO', subtitle: 'Buscadores'}
  ],
  top: [
    {id: 'T001', title: 'PÁGINA WEB', subtitle: 'Empresarial, Landing Page, Otros'},
    {id: 'T002', title: 'REDES SOCIALES', subtitle: 'Facebook, Instagram, Otros'},
    {id: 'T003', title: 'APPLICACIÓN MOVIL', subtitle: 'IOS, Android'},
    {id: 'T004', title: 'SEO Y POSICIONAMIENTO', subtitle: 'IOS, Android'},
    {id: 'T005', title: 'ERP', subtitle: 'Enterprice Relational Procesor'},
    {id: 'T006', title: 'BUSSINES TECHNOLOGY', subtitle: 'IOS, Android'},
    {id: 'T007', title: 'ARTIFICIAL INTELLIGENSE', subtitle: 'IOS, Android'}
  ]
};

let types = [
  {id: 'basic', title: 'BÁSICO', selected: true},
  {id: 'middle', title: 'INTERMEDIO', selected: false},
  {id: 'top', title: 'AVANZADO', selected: false}
];

export default class HomeIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      types: types,
      items: items.basic,
      
      formActiveClass: '',
      formInputNamesClass: '',
      formInputEmailClass: '',

      formType: '',
      formItems: [],

      formNames: '',
      formEmail: '',

      elements: Math.floor(Math.random() * 10) + 4
    }
  }
  
  TypeChange = (type) => {
    types[0].selected = (types[0].id === type ? true : false);
    types[1].selected = (types[1].id === type ? true : false);
    types[2].selected = (types[2].id === type ? true : false);

    this.setState({types: types});
  }

  LevelClick = (type) => {
    this.setState({items: items[type]});
  }

  FormNamesKeyUp = (event: MouseEvent) => {
    this.setState({
      formNames: event.target.value,
      formInputNamesClass: (event.target.value.length > 0 ? '' : 'error')
    });
  }

  FormEmailKeyUp = (event: MouseEvent) => {
    this.setState({
      formEmail: event.target.value,
      formInputEmailClass: (event.target.value.length > 0 ? '' : 'error')
    });
  }

  FormPrevious = (event: SubmitEvent) => {

    let type = event.target.querySelector('input[name="rgb-level"]:checked').value;
    let items = [];

    this.state.items.map((value, index) => {
      items.push({
        key: 'chk_items[' + value.id+']',
        value: event.target.querySelector('input[name="chk-item-'+value.id+'"]').checked ? '1' : '0'
      });
    });

    this.setState({
      formType: type,
      formItems: items,
      formActiveClass: 'active active-form'
    });

    event.preventDefault();
  }

  FormSend = (event: SubmitEvent) => {

    if (! this.state.formNames)
    {
      this.setState({
        formInputNamesClass: 'error'
      });

      return false;
    }

    if (! this.state.formEmail)
    {
      this.setState({
        formInputEmailClass: 'error'
      });

      return false;
    }

    let message_html = 'Type: ' + this.state.formType + "\n";

    this.state.formItems.map((value, index) => {
      message_html += value.key + ': ' + (value.value) + "\n";
    });

    this.setState({formActiveClass: 'active active-loader'});

    let instance = this;
    
    window.emailjs.send(
      'mailjet', 
      'template_HHZyjW1p', 
      {
        from_name: this.state.formNames,
        from_email: this.state.formEmail,
        message_html: message_html
      }
    ).then(res => {
      instance.setState({formActiveClass: 'active active-success', items: []});
      instance.setState({formActiveClass: 'active active-success', items: items['basic']});

      instance.TypeChange('basic');

      setTimeout(function(){
        instance.setState({formActiveClass: ''});
      }, 2000);
    })
    // Handle errors here however you like, or use a React error boundary
    .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))

    event.preventDefault();
  }

  FormCancel = (event: SubmitEvent) => {
    this.setState({formActiveClass: ''});

    event.preventDefault();
  }

  renderTypes() {
    return this.state.types.map((value, index) => {
      return (
        <li key={value.id}>
          <input type="radio" 
            name="rgb-level" 
            id={'Rbg-level-' + value.id} 
            value={value.id}
            checked={value.selected === true} 
            onChange={() => this.TypeChange(value.id)}/>
          <label htmlFor={'Rbg-level-' + value.id} onClick={() => this.LevelClick(value.id)}>{value.title}</label>
        </li>
      );
    });
  }

  renderItems() {
    return this.state.items.map((value, index) => {
      return (
        <li key={value.id}>
          <input type="checkbox" 
            name={'chk-item-' + value.id} 
            id={'Chk-' + value.id}
            value/>
          <label htmlFor={'Chk-' + value.id} className=""></label>
          <label htmlFor={'Chk-' + value.id} className="Form-Item-Selector"></label>
          <strong className="Form-Item-Title">{value.title}</strong>
          <label className="Form-Item-SubTitle">{value.subtitle}</label>
        </li>
      )
    });
  }

  renderElements() {
  
    let list = [];
    let types = ['cubic', 'triangle-1', 'triangle-2', 'triangle-3'];
    let widths = ['width-1', 'width-2'];
    let colors = ['color-1', 'color-2'];

    for (var i = 0; i < this.state.elements; i++) 
    {
      let type = types[Math.floor(Math.random() * 4)];
      let width = widths[Math.floor(Math.random() * 2)];
      let color = colors[Math.floor(Math.random() * 2)];

      let pos_vert = Math.floor(Math.random() * 90);
      let pos_hor = Math.floor(Math.random() * 90);

      list.push(
        <div key={i} 
          className={'element ' + type + ' ' + width + ' ' + color} 
          style={{top: pos_vert + '%', left: pos_hor + '%'}}>
        </div>
      );
    }

    return list;
  }

  render() {
    return (
      <div>
        <div className="App-Background-green"></div>
        <div className="App-Background-yellow"></div>
        <div className="App-Elements">
          {this.renderElements()}
        </div>
        <div className="App-Content">
          <h1 className="App-title">
            Desarrolladores de <strong>Tecnología</strong>
          </h1>
          <h6 className="App-subtitle">Y MUCHO MÁS POR EXPORAR</h6>
          <p className="App-phrase">
            "La creatividad no está en lo que ves, está en el potencial de las ideas"
          </p>
        </div>
        <div className="App-Phone">
          <form action="" method="POST" className="App-Form" onSubmit={this.FormPrevious}>
            <h5 className="App-Form-Title">Consulta</h5>
            <ul className="App-Form-Level">
              {this.renderTypes()}
            </ul>

            <h6 className="App-Form-SubTitle">APPLICACIONES</h6>
            <ul className="App-Form-Items">
              {this.renderItems()}
            </ul>
            <button type="submit" className="App-Form-Button" onClick={() => this.SendClick}>
              Enviar
              &nbsp;&nbsp;&nbsp;&nbsp;
              <img src="arrow-next-green.png" alt="" />
            </button>
          </form>
          <div className={'App-Phone-Waiter ' + this.state.formActiveClass}>
            <div className="Phone-Waiter-Box">
              <div className="Waiter-Box-Form">
                <div className="Waiter-Box-Header">
                  <h6>Datos de contacto</h6>
                  <small>Ingrese nombres y correo</small>
                </div>
                <div className="Waiter-Box-Content">
                  <input type="text" 
                    name="txt_names" 
                    placeholder="Nombres" 
                    className={this.state.formInputNamesClass}
                    onKeyUp={this.FormNamesKeyUp} />
                  <input type="email" 
                    name="txt_email" 
                    placeholder="E-mail" 
                    className={this.state.formInputEmailClass}
                    onKeyUp={this.FormEmailKeyUp} />
                </div>
                <div className="Waiter-Box-Footer">
                  <button type="button" onClick={this.FormCancel}><strong>Cancelar</strong></button>
                  <button type="button" onClick={this.FormSend}>Aceptar</button>
                </div>
              </div>
              <div className="Waiter-Box-Loader">
                <img src="loader.gif" alt="loader" />
              </div>
              <div className="Waiter-Box-Success">
                <img src="check-ic.png" alt="loader" />
                <p>Completado</p>
              </div>
            </div> 
          </div>
          <div className="App-Phone-Person"></div>
        </div>
      </div>
    )
  }
}