import React, {Component} from 'react';

export default class AboutIndex extends Component {
	render() {
		return (
			<div>
				<div class="App-container">
					<h1>Bienvenido</h1>
				</div>				
			</div>
		)
	}
}